window.addEventListener('load', () => {
    /* global dataLayer */
    window.dataLayer = window.dataLayer || [];

    const contactFormLinks = document.querySelectorAll('.js-contact-form-link');
    contactFormLinks.forEach((link) => {
        link.addEventListener('click', () => dataLayer.push({
            event: 'contact-click',
            linkText: link.innerText,
        }));
    });

    const buttons = document.querySelectorAll('.link-blue-square');
    buttons.forEach((button) => {
        button.addEventListener('click', () => dataLayer.push({
            event: 'button-click',
            linkText: button.innerText,
            href: button.getAttribute('href'),
        }));
    });

    const bookLinks = document.querySelectorAll('.js-book-link');
    bookLinks.forEach((link) => {
        link.addEventListener('click', () => dataLayer.push({
            event: 'book-click',
            bookTitle: link.dataset.bookTitle,
            bookAuthor: link.dataset.bookAuthor,
            href: link.getAttribute('href'),
        }));
    });

    const partnerLinks = document.querySelectorAll('.js-partner-link');
    partnerLinks.forEach((link) => {
        link.addEventListener('click', () => dataLayer.push({
            event: 'partner-click',
            linkText: link.innerText,
            href: link.getAttribute('href'),
        }));
    });

    const magazineArchiveLinks = document.querySelectorAll('.js-magazine-archive-link');
    magazineArchiveLinks.forEach((link) => {
        link.addEventListener('click', () => dataLayer.push({
            event: 'magazine-archive-click',
            linkText: link.innerText,
            href: link.getAttribute('href'),
        }));
    });
});
